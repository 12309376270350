import React from "react";
import { useParams } from "react-router-dom";

import Header from "../components/Header";
import { LocationheaderWhite } from "../components/LocationHeader";
import MapHeader from "../Location/components/MapHeader";
import Ticket from "./components/Ticket";
import TicketDone from "./components/TicketDone";
import Position from "./components/Position/Position";
import Guide from "./components/Guide";
import Media from "./components/Media";

import NotFound from "./components/NotFound";
import Canceled from "./components/Canceled";
import Rating from "./components/Rating";
import FormRating from "./components/FormRating";
import Footer from "../components/Footer";
import { useTicket, Status } from "../../hooks/useTicket";
import Absent from "./components/Absent";

function TicketPage() {
  let { slug } = useParams();
  const ticket = useTicket(slug);

  const isOpen = true;
  // const isOpen = ticket.location.some((e)=> e.status === "AVAILABLE");

  // TODO: check for deleted ticket
  function getTicketBody() {
    if (ticket.status === Status.ABSENT) {
      return <Absent number={ticket.details.number} />;
    }

    if (ticket.status === Status.CANCELED) {
      return <Canceled number={ticket.details.number} />;
    }

    if (ticket.status === Status.DONE) {
      let { rating } = ticket.queueInfo.ticketModules || {};
      let placeId =
        rating &&
        ticket.queueInfo.ticketModules.form &&
        ticket.queueInfo.ticketModules.form.placeId;

      return (
        <>
          {rating && <Rating rating={rating} placeId={placeId} />}
          <TicketDone ticket={ticket} />
        </>
      );
    }

    let { steps, form, youtube } = ticket.queueInfo.ticketModules || {};

    return (
      <>
        <Ticket ticket={ticket} />
        {ticket.status === Status.WAITING && <Position ticket={ticket} />}
        {steps && (
          <Guide steps={steps} avatar={ticket.location.avatar || null} />
        )}
        {form && <FormRating Form={form} />}
        {youtube && <Media youtube={youtube} />}
      </>
    );
  }

  // TODO handle loading state
  if (ticket === null) {
    return null;
  }

  if (ticket.status === Status.ERROR) {
    return (
      <>
        <Header />
        <NotFound />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <MapHeader location={ticket.location} />
      <div className="content">
        <LocationheaderWhite location={ticket.location} isOpen={isOpen} />
        {getTicketBody()}
      </div>
      <Footer />
    </>
  );
}

export default TicketPage;
