import styled from "styled-components";
import PathTimeModule from "./PathTimeModule";

import { ReactComponent as LocationSVG } from "../../assets/location.svg";

const PathTimeLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  background-color: #fff;

  .logo {
    height: 50px;
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }

  svg {
    height: 50px;
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }

  span {
    font-size: 17px;
    font-weight: 700;
    color: #37474f;
  }
`;

const Row = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;

  font-size: 15px;
  font-weight: 700;
  color: #37474f;
  justify-content: space-around;
  align-items: center;
  span {
  }
  a {
    font-size: 11px;
    font-weight: 400;
  }
`;

const PathTime = ({ location, setTime, time, vehicule, setVehicule }) => {
  const safelocation = location.address.split(" ").join("+");
  const geoUrl = `geo:0,0?q=${safelocation}+${location.zipCode}+${location.city}`;
  return (
    <PathTimeLayout>
      <Header>
        {location.avatar ? (
          <img src={location.avatar} alt="logo" className="logo" />
        ) : (
          <LocationSVG />
        )}
        <span>
          Dans combien de temps <br /> pouvez-vous être sur place ?
        </span>
      </Header>

      <Row>
        <span>Je peux être là dans : </span>
        <a href={geoUrl}>Calculer mon temps de trajet</a>
      </Row>

      <PathTimeModule
        location={location}
        setTime={setTime}
        time={time}
        vehicule={vehicule}
        setVehicule={setVehicule}
      />
    </PathTimeLayout>
  );
};

export default PathTime;
