import { useState, useEffect } from "react";

function fetchClient() {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v2/me`)
    .then((resp) => resp.json())
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

export function useClient() {
  const [client, setClient] = useState(null);
  useEffect(() => {
    fetchClient()
      .then((a) => {
        if (a && a.client) {
          setClient(a.user);
        } else {
          setClient({ tickets: [] });
        }
      })
      .catch(() => setClient({ tickets: [] }));
  }, []);

  return { client };
}
