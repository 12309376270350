import React from "react";
import styled from "styled-components";

import { ReactComponent as InfoSVG } from "../assets/info.svg";

const InfoBarLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1af3de;
  padding: 1rem;
  cursor: pointer;

  .info-bar-head {
    height: 100%;
    svg {
      height: 100%;
    }
  }

  .info-bar-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #37474f;
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .right {
    transform: rotate(-45deg);
  }
`;

const InfoBar = ({ onClick }) => {
  return (
    <InfoBarLayout onClick={onClick}>
      <div className="info-bar-head">
        <InfoSVG height={39} width={39} />
      </div>
      <div className="info-bar-content">
        <div>Première fois ?</div>
        <div>Prenez 1min pour tout comprendre.</div>
      </div>
      <div className="info-bar-tail">
        <i class="arrow right"></i>
      </div>
    </InfoBarLayout>
  );
};

export default InfoBar;
