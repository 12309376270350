import ticketsWithTime from "./ticketWithTime";

function nextAvailableTime(queue, start, durationS) {
  let tickets = ticketsWithTime(queue.tickets);
  let available = new Date(start);
  for(let t of tickets) {
    if(t.time.getTime() > start &&  t.timeBefore!=null && t.timeBefore >= durationS *1000) {
      //return new Date(t.time.getTime() - t.timeBefore);
      return available;
    }
    available = new Date(t.time.getTime() + t.durationS * 1000);
  }

  return available;

  /*
  
  let queueStart = +new Date();
  let available = null;
  let durationMS = durationS * 1000;

  for (let t of tickets) {
    if (queueStart >= start && t.time - queueStart >= durationMS) {
      available = new Date(queueStart);
    }
    queueStart = new Date(+queueStart + t.duration * 60 * 1000);
  }

  if (available == null) {
    available = new Date(Math.max(queueStart, start));
  }

  return available;
  */
}

export default nextAvailableTime;
