import React, { useState } from "react";
import { ReactComponent as CancelSVG } from "../assets/cancel.svg";

import { ModalContainer, Modal } from "./TicketModal";
import { TicketContainer } from "./TicketLayout";
import TicketWaiting from "./TicketWaiting";
import TicketStarted from "./TicketStarted";
import TicketMissing from "./TicketMissing";

const Ticket = ({ ticket }) => {
  const [modalVisible, setModalVisible] = useState(false);

  function toggleModal() {
    setModalVisible(!modalVisible);
  }

  function handleCancel() {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v2/ticket/${ticket.id}/status`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify("CANCELED"),
    }).catch((err) => console.error(err));
  }

  const isTicketStarted = ticket.startedTime != null;
  const isTicketAbsent = ticket.doctorId == null;

  return (
    <TicketContainer>
      <h2 className="ticket-title">Voici votre ticket 👇 </h2>
      {isTicketStarted ? (
        <TicketStarted ticket={ticket} onCancel={toggleModal} />
      ) : isTicketAbsent ? (
        <TicketMissing ticket={ticket} onCancel={toggleModal} />
      ) : (
        <TicketWaiting ticket={ticket} onCancel={toggleModal} />
      )}

      <ModalContainer
        isVisible={modalVisible}
        onClick={(e) => {
          if (e.target.children.length === 1) {
            toggleModal();
          }
        }}
      >
        <Modal>
          <CancelSVG />
          <p>Êtes-vous sûr de vouloir annuler ?</p>
          <span>
            Cette action est irreversible, vous <br /> perdrez votre ticket
            ainsi que votre <br /> place dans la file d’attente
          </span>
          <button className="cancel" onClick={handleCancel}>
            annuler mon ticket
          </button>
          <button onClick={toggleModal}>conserver mon ticket</button>
        </Modal>
      </ModalContainer>
    </TicketContainer>
  );
};

export default Ticket;
